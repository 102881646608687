<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";

export default {
  page: {
    title: "XIVAPI Laravel Docs",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
  },
 
data: function() {
    return {
      result: '',
    };
  }
};
</script>

<template>
  <Layout>
    <div class="row align-items-center">
        <div class="col-sm-6">
            <div class="page-title-box">
                <h4 class="page-title">XIVAPI Laravel Docs</h4>
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">XIVDATA</li>
                    <li class="breadcrumb-item">XIVAPI Laravel</li>
                    <li class="breadcrumb-item active">Search</li>
                </ol>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-xl-2">
                            <div class="card">
                                <div class="nav flex-column nav-pills">
                                    <router-link to="/xivapi" class="nav-link"><i class="fas fa-home"></i> Home</router-link>
                                    <router-link to="/xivapi/release" class="nav-link"><i class="far fa-user"></i> Release Information</router-link>
                                    <router-link to="/xivapi/search" class="nav-link"><i class="far fa-envelope"></i> Search</router-link>
                                    <router-link to="/xivapi/character" class="nav-link"><i class="fas fa-horse"></i> Character</router-link>
                                    <router-link to="/xivapi/freecompany" class="nav-link"><i class="fas fa-cat"></i> Free Company</router-link>
                                    <router-link to="/xivapi/linkshell" class="nav-link"><i class="fas fa-trophy"></i> Linkshell</router-link>
                                    <router-link to="/xivapi/content" class="nav-link"><i class="fas fa-images"></i> Content</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 col-lg-5 col-xl-6">
                            <pre>
                                <code>
                                    ```php
                                    $api->character->search($name, $server, $page);
                                    $api->character->get($id, $data = [], $extended = false);
                                    $api->character->verify($id);
                                    $api->character->update($id);
                                    $api->character->delete($id);
                                    ```
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>